
import type { SwiperOptions } from "swiper"
import type { ProgramEventWithDate } from "./IndexCalendar.vue"
import indexSwiperMixin from "@/mixins/index-swiper"

export default indexSwiperMixin.extend({
  props: {
    events: {
      type: Array as VuePropType<ProgramEventWithDate[]>, required: true,
    },
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 20,
        slidesPerView: "auto",
        navigation: {
          prevEl: `#${this.prevId}`,
          nextEl: `#${this.nextId}`,
          disabledClass: "index-swiper-nav-disabled",
        },
      }
    },
  },
})
