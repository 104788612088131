// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._41AXLFco{align-items:center;display:flex;justify-content:space-between}._41AXLFco>*+*{margin-left:max(1.5rem,min(2.5vw,3rem))}._41AXLFco .index-swiper-nav-disabled{color:rgba(0,0,0,.3);cursor:default}.W9dHokR9{align-items:center;display:flex}.jyxkZL5J{--size:1.5rem}.LH1yZwla,.GoaxErHe{align-items:center;color:var(--color-primary);display:flex;height:2.63rem;justify-content:center;transform:var(--transform);transition:.2s ease-in-out;transition-property:transform;width:2.63rem}.LH1yZwla:not(.index-swiper-nav-disabled):active,.GoaxErHe:not(.index-swiper-nav-disabled):active{transform:scale(.8) var(--transform)}.GoaxErHe{--transform:rotateY(0);margin-right:max(.84rem,min(1.25vw,1.5rem))}.LH1yZwla{--transform:rotateY(180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "_41AXLFco",
	"nav": "W9dHokR9",
	"navIcon": "jyxkZL5J",
	"navNext": "LH1yZwla",
	"navPrev": "GoaxErHe"
};
module.exports = ___CSS_LOADER_EXPORT___;
