// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZlqZRMUV{color:var(--color-gray-4);display:flex;flex-direction:column;overflow:hidden;transition:.2s ease-in-out;transition-property:transform}.ZlqZRMUV:active{transform:scale(.95)}.ZlqZRMUV>*+*{margin-top:max(.56rem,min(.83vw,1rem))}.ivd7H5wc{border-radius:inherit;height:max(11.25rem,min(14.27vw,17.13rem));-o-object-fit:cover;object-fit:cover;width:100%}.hhC4aiq6{border-radius:max(.7rem,min(1.04vw,1.25rem));position:relative}.kNzAvM6V{align-items:flex-end;bottom:0;display:flex;flex-wrap:wrap;gap:max(.11rem,min(.16vw,.19rem)) max(.21rem,min(.31vw,.38rem));left:0;padding:max(.32rem,min(.47vw,.56rem)) max(.21rem,min(.31vw,.38rem));position:absolute}.oXS3MugA{align-items:center;background:rgba(0,0,0,.5);border-radius:9999px;color:#fff;display:flex;font-size:.75rem;font-weight:600;line-height:1.2;overflow:hidden;padding:max(.28rem,min(.42vw,.5rem));text-transform:capitalize;white-space:nowrap}.oXS3MugA>*+*{margin-left:max(.32rem,min(.47vw,.56rem))}.eu\\+Guy\\+U{border-radius:9999px;height:max(.21rem,min(.31vw,.38rem));overflow:hidden;width:max(.21rem,min(.31vw,.38rem))}.aYfZeNYl{align-items:center;display:flex;justify-content:space-between}.aYfZeNYl>*+*{margin-left:max(.5rem,min(.83vw,1rem))}.LHDJgLSK{align-items:center;display:flex;font-size:.75rem;font-weight:600;line-height:1.2;overflow:hidden;padding:.31rem max(0rem,min(.42vw,.5rem))}.LHDJgLSK:first-child{flex-shrink:0}.LHDJgLSK span{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.LHDJgLSK>*+*{margin-left:max(.25rem,min(.42vw,.5rem))}.UWEYFdYa{--size:0.75rem;color:#252525;opacity:.3}.NBRIOZlF{font-size:max(1.13rem,min(1.04vw,1.25rem));font-weight:600;line-height:1.2;-webkit-line-clamp:4}.hTjG2W1E,.NBRIOZlF{word-wrap:break-word;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}.hTjG2W1E{font-size:1rem;line-height:1.4;-webkit-line-clamp:5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"event": "ZlqZRMUV",
	"eventCover": "ivd7H5wc",
	"eventCoverWrapper": "hhC4aiq6",
	"eventTags": "kNzAvM6V",
	"eventTag": "oXS3MugA",
	"eventTagCircle": "eu+Guy+U",
	"eventHeader": "aYfZeNYl",
	"eventHeaderItem": "LHDJgLSK",
	"eventHeaderItemIcon": "UWEYFdYa",
	"eventTitle": "NBRIOZlF",
	"eventText": "hTjG2W1E"
};
module.exports = ___CSS_LOADER_EXPORT___;
